/* Modal overlay to darken the background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other elements */
  }
  
  /* Modal content box */
  .modal-content {
    background-color: #636363;
    padding: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  /* Close button styling */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #242424;
  }
  
  /* Form styling */
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.2s ease;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #007bff;
  }
  
  /* Button styling */
  .submit-btn {
    background-color: #007bff;
    color: rgb(0, 0, 0);
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  