/* Global Styles */
html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HERO SECTION */
.hero {
  background: url('../public/images/portfolio/wedding/hero.png') no-repeat center center;
  background-size: cover;
  height: 60vh; /* Adjust height depending on screen size */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.hero-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Ensure visibility of the text */
  color: white;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Cover the whole hero section */
  height: 60vh; /* Stretch to cover the entire section */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  max-width: 90%; /* Prevent text overflow on smaller screens */
  line-height: 1.4; /* Improve readability */
}

.btn-primary {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .hero {
    height: 30vh;
  }

  .hero-overlay {
    height: 30vh;
    padding: 0px;
  }

  .hero h1 {
    font-size: 1.8rem; /* Reduce font size on smaller screens */
  }

  .hero p {
    font-size: 1rem; /* Scale down the text */
    max-width: 90%; /* Allow text to fill the screen on mobile */
    margin-bottom: 1rem;
  }

  .btn-outlined {
    padding: 6px 12px;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
}

/* ABOUT SECTION */
.about {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 50px 0;
}

.about-container {
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.about-text {
  flex: 1;
  text-align: left;
  margin-right: 50px;
}

.about-image img {
  width: 300px;
  margin-top: 70px;
  height: auto;
  border-radius: 8px;
}

/* PORTFOLIO SECTION */
.portfolio {
  text-align: center;
}

.portfolio h2 {
  margin-bottom: 30px;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive columns */
  gap: 20px;
}

.portfolio-item {
  position: relative;
  display: block;
}

.portfolio-item img {
  width: 100%;
  display: block;
  transition: transform 0.3s ease;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* SERVICES SECTION */
.services {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top, so they expand as needed */
  padding: 50px 20px; /* Add padding for spacing */
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive columns */
  grid-gap: 20px;
  max-width: 1200px;
  margin: 20px;
}

.service-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  word-wrap: break-word; /* Ensure long words break onto the next line */
}

.service-item:hover {
  transform: translateY(-5px);
}

.service-item h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.service-item p {
  font-size: 1rem;
  color: #666;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .services {
    padding: 20px 10px; /* Reduce padding for mobile */
  }

  .services-grid {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }

  .service-item {
    margin-bottom: 20px; /* Add space between items on mobile */
  }

  .service-item p {
    font-size: 0.9rem; /* Adjust font size on mobile */
  }
}
/* MASONRY GRID SECTION */
.my-masonry-grid {
  display: flex;
  margin: 80px; /* gutter size offset */
  width: auto;
  perspective: 1000px;
}

.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

.masonry-item {
  margin-bottom: 30px;
  overflow: visible;
  position: relative;
}

.masonry-item img {
  width: 93%;
  height: 100%;
  display: block;
  border-radius: 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.masonry-item:hover img {
  z-index: 30;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.3);
}

/* HEADER */
.header {
  background-color: #fff;
  padding: 0px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  height: 100px;
}

/* Navigation - Desktop */
.nav ul {
  list-style: none;
  display: flex;
  gap: 30px;
  padding: 0;
}

.nav a {
  text-decoration: none;
  font-size: 18px;
  color: #333;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: #007bff;
}

/* Burger Menu - Mobile */
/* Burger Menu - Mobile */
.burger-menu {
  margin-top: 13px;
  display: none; /* Hidden on larger screens */
}

@media (max-width: 768px) {
  .burger-menu {
    display: block; /* Show burger menu icon on mobile */
    cursor: pointer;
    font-size: 24px;
    position: absolute; /* Ensure it's positioned absolutely */
    right: 20px; /* Adjust distance from the right edge */
    top: 20px; /* Adjust distance from the top */
}

  .nav ul {
    display: none; /* Hide the nav links on mobile */
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ensure the nav takes full width on mobile */
  }

  .nav ul.show {
    display: flex; /* Show when burger menu is active */
  }

  .nav ul li {
    margin-bottom: 10px; /* Add some spacing between nav items */
  }
}

/* Footer */
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 40px 0;
  width: 100%;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer p {
  font-size: 14px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #007bff;
}

/* Responsive Design */
/* Mobile-Friendly Adjustments */
@media (max-width: 768px) {
  .hero {
    height: 30vh;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .about-container {
    flex-direction: column; /* Stack content vertically on mobile */
    align-items: center;
    padding: 30px;
  }

  .about-text {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about-image img {
    margin-top: 20px;
  }

  .footer p {
    margin: 20px;
  }

  .my-masonry-grid {
    margin: 20px;
  }

  .masonry-item img {
    width: 100%; /* Ensure masonry images scale properly */
  }

  .masonry-item:hover img {
    transform: scale(1);
    z-index: 0;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.3);
  }
}


.category-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.category-button {
  padding: 10px 20px;
  margin: 0 10px; /* Adds a gap between each button */
  border: 2px solid #333; /* Adds the outline */
  background-color: transparent; /* Default background (unselected) */
  color: #333;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0; /* Square corners */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.category-button:hover {
  background-color: #f0f0f0; /* Lighter gray on hover */
}

.category-button.active {
  background-color: #333; /* Gray fill for selected buttons */
  color: #fff; /* White text for selected buttons */
}