/* Base styles for the button */
.btn-primary {
    background-color: #007bff; /* Primary color */
    color: white; /* Text color */
    padding: 12px 24px; /* Padding for the button */
    border-radius: 50px; /* Rounded corners */
    border: none; /* No border */
    font-size: 16px; /* Font size */
    font-weight: bold; /* Font weight */
    cursor: pointer; /* Pointer on hover */
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1px; /* Space between letters */
    transition: all 0.3s ease; /* Smooth transition */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  /* Hover effect */
  .btn-primary:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-3px); /* Slight lift on hover */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow on hover */
  }
  
  /* Active button effect */
  .btn-primary:active {
    background-color: #004085; /* Even darker blue when clicked */
    transform: translateY(0); /* Reset position */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Reset shadow */
  }
  
  .btn-outline {
    background-color: transparent; /* Transparent background */
    color: #ffffff; /* Text color */
    padding: 10px 24px; /* Padding for the button */
    margin-bottom: 10px;
    border: 2px solid #333; /* Solid border */
    border-radius: 0; /* Sharp corners */
    font-size: 16px; /* Font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1px; /* Slight spacing between letters */
    transition: all 0.3s ease; /* Smooth transition */
    display: inline-block;
    outline: none;
  }
  
  /* Hover effect */
  .btn-outline:hover {
    background-color: #b3b3b3; /* Dark background on hover */
    color: white; /* White text on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  /* Active button effect */
  .btn-outline:active {
    transform: translateY(0); /* Reset position */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow when clicked */
  }